/* eslint-disable no-unused-vars */
import _ from 'lodash'
import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../../helpers'
import * as styles from './event-style.module.scss'
import IconLocation from '../../assets/icons/reflections-group-icon-location.svg'
import IconCalendar from '../../assets/icons/reflections-group-icon-calendar.svg'
import Seo from '../../components/Seo'
import {
  Spinner,
  Cta,
  Navbar,
} from '../../components'

const EventPage = (props) => {
  const { params, slug } = props
  const [event, setEvent] = useState({})
  const [loading, setLoading] = useState(true)
  const { t, i18n } = useTranslation()
  const getEvent = useCallback(async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'event',
      params: !i18n.language.toLowerCase().includes('en') ? {
        lang: i18n.language.toLowerCase(),
        slug,
        // ...params,
      } : {
        slug
      },
    }).then((resp) => {
      const data = resp[0]
      setEvent(data)
      setLoading(false)
    })
  }, [slug])

  useEffect(() => {
    getEvent()
  }, [slug])

  if (loading) {
    return <Spinner loading={loading} />
  }
  return (
    <div className={styles.event__container}>
      <Seo title="Kamo - Event" />
      <div className={styles.event__box}>
        <div className={styles.event__title}>
          <h2>{_.get(event, 'title.rendered')}</h2>
          <p>{_.get(event, 'category_name')}</p>
          <p className={styles.withIcon}>
            <IconLocation width={24} />
            <span>{_.get(event, 'acf.event_group.place')}</span>
            <span>{_.get(event, 'acf.event_group.location')}</span>
          </p>
        </div>
        <Navbar
          title={t('menu.bookWith')}
          loading={loading}
          t={t}
        />
        <div className={styles.event__section__date}>
          <div className={styles.event__section__date__box}>
            <div>
              <p className={styles.withIcon}><IconCalendar width={24}/> <span>{t('events.startDate')}</span></p>
            </div>
            <p>{_.get(event, 'acf.event_group.start_date')}</p>
          </div>
          <div className={styles.event__section__date__box}>
            <div>
              <p className={styles.withIcon}><IconCalendar width={24}/> <span>{t('events.endDate')}</span></p>
            </div>
            <p>{_.get(event, 'acf.event_group.end_date')}</p>
          </div>
          <div className={styles.event__section__date__box}>
            <div>
              <p className={styles.withIcon}><IconCalendar width={24}/> <span>{t('events.startTime')}</span></p>
            </div>
            <p>{_.get(event, 'acf.event_group.start_time')}</p>
          </div>

        </div>
        <div className={styles.event__section__info__images}>
          <div>
            <img src={_.get(event, 'acf.event_group.landscape_image.url')}/>
          </div>
          <div>
            <img src={_.get(event, 'acf.event_group.portrait_image.url')}/>
          </div>

        </div>
        <div className={styles.event__section__info}>
          <div className={styles.event__section__info__text} >
            <div
              className="rich-text"
              dangerouslySetInnerHTML={{ __html: _.get(event, 'acf.event_group.description') }} />
          </div>

          <div className={styles.event__section__info__price}>
            <p>¥ {_.toNumber(_.get(event, 'acf.event_group.price')) } {t('events.perPerson')}</p>
          </div>

        </div>
        <div>
          <Cta />
        </div>
      </div>
    </div>
  )
}

export default EventPage
