// extracted by mini-css-extract-plugin
export var active = "event-style-module--active--b370b";
export var arrow = "event-style-module--arrow--3593c";
export var arrowDisabled = "event-style-module--arrow--disabled--3c2e2";
export var arrowLeft = "event-style-module--arrow--left--eef03";
export var arrowRight = "event-style-module--arrow--right--5c69c";
export var blink = "event-style-module--blink--bb138";
export var bookingBtn = "event-style-module--booking-btn--9a8bf";
export var bookingPageHeader = "event-style-module--booking-page-header--0c050";
export var checkout = "event-style-module--checkout--5fa6d";
export var commentsSlider = "event-style-module--commentsSlider--c5357";
export var dot = "event-style-module--dot--28dc5";
export var dots = "event-style-module--dots--dacd7";
export var equallyBound = "event-style-module--equallyBound--74088";
export var event__box = "event-style-module--event__box--a2b50";
export var event__container = "event-style-module--event__container--30c3a";
export var event__section__date = "event-style-module--event__section__date--70cad";
export var event__section__date__box = "event-style-module--event__section__date__box--b9e48";
export var event__section__info = "event-style-module--event__section__info--ae2c7";
export var event__section__info__images = "event-style-module--event__section__info__images--a8a81";
export var event__section__info__price = "event-style-module--event__section__info__price--c3ba3";
export var event__section__info__text = "event-style-module--event__section__info__text--528e2";
export var event__title = "event-style-module--event__title--12467";
export var fader = "event-style-module--fader--5fe27";
export var fader__slide = "event-style-module--fader__slide--5cdce";
export var featuredSlider = "event-style-module--featuredSlider--63f1a";
export var globalWrapper = "event-style-module--global-wrapper--afbbb";
export var headerIcons = "event-style-module--header-icons--9f2fa";
export var homepage = "event-style-module--homepage--f50a9";
export var homepageReviews = "event-style-module--homepage-reviews--821f7";
export var isOpenMenu = "event-style-module--is-open-menu--3134e";
export var keenSlider = "event-style-module--keen-slider--58f2d";
export var lineMove = "event-style-module--lineMove--27a92";
export var logo = "event-style-module--logo--271ee";
export var navigationWrapper = "event-style-module--navigation-wrapper--54be7";
export var scrollNav = "event-style-module--scrollNav--bafbc";
export var scrollNavWrap = "event-style-module--scrollNavWrap--a92dd";
export var slickSlide = "event-style-module--slick-slide--9f2aa";
export var startTxtArea = "event-style-module--startTxtArea--fe8cb";
export var withIcon = "event-style-module--withIcon--5d739";