import React from 'react'
import { Router } from '@reach/router'
import _ from 'lodash'
import EventTemplate from '../templates/event'

const EventPage = (props) => {
  const path = _.get(props, 'pageContext.locale') === 'en' ? '/event/' : `${_.get(props, 'pageContext.locale')}/event/`
  return (
    <Router>
      <EventTemplate {...props} path={path} />
      <EventTemplate {...props} path={`${path}:slug`} />
      <EventTemplate {...props} path={`${path}:id`} />
    </Router>
  )
}

export default EventPage
